import React, {Component} from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import {Header} from './Header.js'
import {Footer} from './Footer'
import {PaymentStatus} from './processing-summary/PaymentStatus'
import {PaymentMessage} from './PaymentMessage'
import processingImage from '../assets/images/processing-summary/grey-spinner.svg'
import errorImage from '../assets/images/processing-summary/red-cross.svg'
import {AppStateContext} from './App.state'
import {PaymentForm} from './payment-form/PaymentForm'
import {calculateTotalPaymentAmount} from './payment-form/calculateTotalPaymentAmount'
import {SET_PAYMENT} from './payment-form/collect-payment/options/Actions'
import {ErrorPage} from './ErrorPage'
import doeLogo from '../assets/images/header/doeLogo.png'
import snswLogo from '../assets/images/header/snswLogo.png'
import transportLogo from '../assets/images/header/transportLogo.png'
import {RefundForm} from './processing-summary/RefundForm'
import errorIcon from '../assets/images/banner/error.svg'
import infoIcon from '../assets/images/banner/info.svg'
import warningIcon from '../assets/images/banner/warning.svg'

const TOO_MANY_REQUESTS = 429

export class App extends Component {
    static contextType = AppStateContext

    state = {
      payment: null,
      refund: null,
      loading: true,
      processing: false,
      paymentComponents: [],
      rateLimitExceeded: false,
      splitPaymentFeatureDisabled: false,
      headerImage: undefined,
      headerImageAlt: undefined,
      titleText: '',
      errorText: '',
      loadingText:'',
      notifications: [],
    }

    async componentDidMount() {
      const {paymentReference, refundReference} = this.props
      const me = this
      axios.interceptors.response.use(
        function (successRes) {
          return successRes
        },
        function (e) {
          if (e.response && e.response.status === TOO_MANY_REQUESTS) {
            me.setState({rateLimitExceeded: true})
          }
          console.error('axios error:', e.message)
          return Promise.reject(e)
        }
      )

      if (window.location.href.indexOf('refundReference') !== -1) {
        this.setState({titleText: 'Process a Refund'})
        this.setState({errorText: 'Refund reference not recognised'})
        this.setState({loadingText: 'Loading refund...'})
      } else {
        this.setState({titleText: 'Collect a payment'})
        this.setState({errorText: 'Payment reference not recognised'})
        this.setState({loadingText: 'Loading payment...'})
      }

      if (paymentReference && !refundReference) {
        await this.getPayment(paymentReference)
        if (this.state.payment) {
          await this.getHeaderImage()
        }
      } else if (!paymentReference && refundReference) {
        await this.getRefund(refundReference)
        if (this.state.refund) {
          await this.getHeaderImage()
        }
      } else {
        this.setState({loading: false})
      }
    }

    getPayment = async (paymentReference) => {
      let paymentResponse
      try {
        paymentResponse = await axios.get(
          `${process.env.REACT_APP_CPP_OTC_BFF_URL || ''}/api/payments/${paymentReference}`)
      } catch (error) {
        this.setState({loading: false, payment: null})
        return
      }

      this.setState({splitPaymentFeatureDisabled: paymentResponse.data.isSplitPaymentFeatureDisabled})
      if (paymentResponse.data.notifications !== null) {
        await this.checkForMessages(paymentResponse.data.notifications)
      }

      switch (paymentResponse.data.payment.status) {
        case 'REQUESTED':
          await this.initialisePayment(paymentReference)
          break

        case 'INITIALISED':
          this.setState({
            loading: false,
            payment: paymentResponse.data.payment,
            paymentComponents: [{
              amount: paymentResponse.data.payment.amount,
              componentType: 'CARD',
            }],
          })
          break

        default:
          this.setState({
            loading: false,
            payment: paymentResponse.data.payment,
            paymentComponents: paymentResponse.data.components,
            processing: true,
          })
          break
      }
    }

    getRefund = async (refundReference) => {
      let refundPaymentResponse
      try {
        refundPaymentResponse = await axios.get(
          `${process.env.REACT_APP_CPP_OTC_BFF_URL || ''}/api/refunds/${refundReference}`)
      } catch (error) {
        this.setState({loading: false, refund: null})
        return
      }
      if (refundPaymentResponse.data.notifications !== null) {
        await this.checkForMessages(refundPaymentResponse.data.notifications)
      }

      switch (refundPaymentResponse.data.refund.status) {
        case 'FAILED':
        case 'APPLIED':
        case 'CANCELLED':
        case 'REQUESTED':
          this.setState({
            loading: false,
            refund: refundPaymentResponse.data.refund,
          })
          break

        case 'IN_PROGRESS':
          this.setState({
            loading: false,
            refund: refundPaymentResponse.data.refund,
            processing: true,
          })
          break
        default: break
      }
    }

    async getHeaderImage() {
      const agencyCode = this.state.payment?.agencyCode || this.state.refund?.agencyCode
      if (agencyCode === 'RMS' || agencyCode.startsWith('UNIFY')) {
        this.setState({headerImage: snswLogo})
        this.setState({headerImageAlt: 'Service NSW Logo'})
      }
      if (agencyCode === 'SBTE_DOE_SF_APP' || agencyCode === 'STRL_DOE_SF_APP') {
        this.setState({headerImage: doeLogo})
        this.setState({headerImageAlt: 'DoE Logo'})
      }
      if (agencyCode === 'TRANSPORT_CONNECT') {
        this.setState({headerImage: transportLogo})
        this.setState({headerImageAlt: 'Transport Logo'})
      }
    }

    initialisePayment = async (paymentReference) => {
      try {
        const initResponse = await axios.put(
          `${process.env.REACT_APP_CPP_OTC_BFF_URL || ''}/api/payments/${paymentReference}`)
        const defaultCardComponent = {amount: initResponse.data.amount, componentType: 'CARD'}
        this.context.dispatch({type: SET_PAYMENT, value: initResponse.data})
        this.setState({
          loading: false,
          payment: initResponse.data,
          paymentComponents: [defaultCardComponent],
        })
      } catch (error) {
        this.setState({paymentNotFound: true, payment: null})
      }
    }

    processPaymentRetry = async () => {
      await this.retryPayForComponents()
    }

    retryPayForComponents = async () => {
      const {paymentReference} = this.props
      try {
        await axios.post(`${process.env.REACT_APP_CPP_OTC_BFF_URL || ''}/api/payments/${paymentReference}/retry`)
      } finally {
        this.setState({processing: true})
      }
    }

    processPayment = async (paymentComponents) => {
      if (paymentComponents.length === 0) {
        this.setState({processing: false})
        return
      }

      const {paymentReference} = this.props
      try {
        await axios.post(`${process.env.REACT_APP_CPP_OTC_BFF_URL || ''}/api/payments`, {
          components: paymentComponents,
          paymentReference,
        })
      } finally {
        this.setState({processing: true, paymentComponents: paymentComponents})
      }
    }

    processRefund = async () => {
      const {refundReference} = this.props
      try {
        await axios.post(
          `${process.env.REACT_APP_CPP_OTC_BFF_URL || ''}/api/refunds/${refundReference}/process-refund`)
      } finally {
        this.setState({processing: true})
      }
    }

    cancelPayment = async () => {
      const {paymentReference} = this.props
      await axios.post(`${process.env.REACT_APP_CPP_OTC_BFF_URL || ''}/api/payments/${paymentReference}/cancel`,
        {reason: 'USER_CANCELLED'})
    }

    cancelPaymentSuccessRedirect = async () => {
      const {paymentReference} = this.props
      const response = await axios.get(`/api/payments/${paymentReference}/agency`)
      window.location.href = `${response.data.cancellationURL}`
    }

    cancelPaymentAndRedirect = async () => {
      await this.cancelPayment()
      await this.cancelPaymentSuccessRedirect()
    }

    cancelRefundAndRedirect = async () => {
      //await this.cancelRefund()
      //await this.cancelRefundSuccessRedirect()
    }

    useAnotherPaymentMethod = async (redirect) => {
      if (redirect) {
        this.setState({processing: false})
      } else {
        const {paymentReference} = this.props
        await axios.post(
          `${process.env.REACT_APP_CPP_OTC_BFF_URL || ''}/api/payments/${paymentReference}/change-payment-method`,
          {reason: 'USER_CANCELLED'})
      }
    }

    checkForMessages(notifications) {
      const processReponse = notifications.map(msg => ({
        msgDesc: msg.msgDesc,
        bannerStyle: this.getBannerStyle(msg.msgClassification),
        startTimestamp: msg.startTimestamp,
      }))
      this.setState({notifications: processReponse})
    }

    getBannerStyle(classification) {
      let bannerStyle
      if (classification === 'INFORMATION') {
        bannerStyle = {
          icon: infoIcon,
          iconText: 'InformationBanner',
          css: 'snsw-info-bg',
        }
      }
      if (classification === 'ALERT') {
        bannerStyle = {
          icon: warningIcon,
          iconText: 'AlertBanner',
          css: 'snsw-alert-bg',
        }
      }
      if (classification === 'OUTAGE') {
        bannerStyle = {
          icon: errorIcon,
          iconText: 'OutageBanner',
          css: 'snsw-outage-bg',
        }
      }
      return bannerStyle
    }

    render() {
      const {
        payment,
        refund,
        loading,
        processing,
        paymentComponents,
        rateLimitExceeded,
        splitPaymentFeatureDisabled,
        headerImage,
        headerImageAlt,
        titleText,
        errorText,
        loadingText,
        notifications,
      } = this.state
      return (
        <div>
          {rateLimitExceeded && <ErrorPage/>}
          {!rateLimitExceeded &&
                    <div className='App flex flex-col space-y-3 min-h-screen justify-between'>
                      <Header
                        image={headerImage}
                        imageAlt={headerImageAlt}
                        titleText={titleText}
                        notifications={notifications}
                      />
                      {!loading && payment && processing && (
                        <div className='mt-8 w-2/3 h-full self-center'>
                          <AppStateContext.Provider value={this.context}>
                            <PaymentStatus
                                        paymentReference={payment.paymentReference}
                                        amount={calculateTotalPaymentAmount(paymentComponents)}
                                        agencyCode={payment.agencyCode}
                                        roundedAmount={payment.roundedAmount}
                                        cancelPaymentSuccessRedirect={this.cancelPaymentSuccessRedirect}
                                        useAnotherPaymentMethodCallback={this.useAnotherPaymentMethod}
                                        tryDifferentCardCallBack={this.processPaymentRetry}
                                        csrId={payment.csrId}/>
                          </AppStateContext.Provider>
                        </div>
                      )}

                      {!loading && payment && !processing && (
                        <PaymentForm payment={payment}
                                         initialPaymentComponents={this.state.paymentComponents}
                                         splitPaymentFeatureDisabled={splitPaymentFeatureDisabled}
                                         processPaymentCallback={this.processPayment}
                                         cancelPaymentCallback={this.cancelPaymentAndRedirect}/>
                      )}

                      {!loading && refund && (
                        <AppStateContext.Provider value={this.context}>
                          <RefundForm
                            refund={refund}
                            processRefundCallback={this.processRefund}
                            cancelRefundCallback={this.cancelRefundAndRedirect}
                            csrId={refund.csrId}
                          />
                        </AppStateContext.Provider>
                      )}

                      {loading &&
                            <PaymentMessage message={loadingText} image={processingImage}
                                            imageAlt='In progress'/>
                      }
                      {!loading && !payment && !refund &&
                            <PaymentMessage message={errorText} image={errorImage}
                                            imageAlt='Not found'/>
                      }
                      <Footer/>
                    </div>
          }
        </div>
      )
    }
}

App.propTypes = {
  paymentReference: PropTypes.string,
  refundReference: PropTypes.string,
}

export default App
