import React from 'react'
import PropTypes from 'prop-types'

export function Header(props) {
  return (
    <div>
      <div className='bg-white py-2 pr-1 m-2'>
        <div className='flex'>
          <img className='h-12 px-1 ml-32' src={props.image} alt={props.imageAlt}/>
        </div>
      </div>
      {props.notifications.map((notification, index) => (
        <div key={index} className={`notification-banner flex items-center bg-${notification.bannerStyle.css}`}>
          <img className='h-12 ml-32 w-6' src={notification.bannerStyle.icon} alt={notification.bannerStyle.iconText}/>
          <div className='flex-1 font-bold ml-3 mr-40 mt-3 mb-3'>{notification.msgDesc}</div>
        </div>
      ))}
      <div className='flex items-center bg-snsw-gray-3 h-24 shadow'>
        <div className='flex-1 text-4xl font-bold ml-32'>{props.titleText}</div>
      </div>
    </div>

  )
}

Header.propTypes = {
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  titleText: PropTypes.string,
  notifications: PropTypes.array,
}
